.slick-dots li button:before {
  color: var(--light-gray-color);
  opacity: 0.75;
  scale: 1.2;
}
.slick-dots li.slick-active button:before {
  color: var(--primary-color);
}

#scroll-to-projects .slick-list {
  width: 42vw;
  min-width: 250px;
  margin: 50px auto;
}
