.container {
  margin-bottom: 200px;
}

.projectsContainer {
  display: flex;
  justify-content: center;
  gap: 40px;
  width: fit-content;
  flex-wrap: wrap;
}
