.container {
  /* border: 2px blue solid; */
}
.badge {
}
.title {
  font-size: 76px;
  font-weight: 400;
  margin: 20px 0;
  white-space: nowrap;
}
.title > span {
  font-size: 76px;
  color: var(--primary-color);
}
.description {
  color: var(--light-gray-color);
}
.projectsButton {
  margin: 50px 0 50px auto;
}

.introduceStatsContainer {
  display: flex;
  gap: 40px;
  align-items: flex-start;
}
.introduceStatsContainer > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 220px;
}
.statsTitle {
  color: var(--light-gray-color);
}
.statsNumber {
  color: var(--primary-color);
  font-size: 70px;
  white-space: nowrap;
}

@media screen and (max-width: 655px) {
  .title,
  .title * {
    font-size: 45px !important;
    white-space: wrap;
  }
}
