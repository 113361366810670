.container {
  width: 150px;
  height: 200px;
  border: 2px var(--gray-color) solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
  border-radius: 90px;
  transition: all 0.3s ease;
}
.container:hover {
  border-color: var(--primary-color);
  transform: translateY(-10px) scale(1.05);
}
.container:hover .title {
  color: var(--primary-color);
}

.img {
  object-fit: contain;
  width: 90px;
  height: 90px;
}

.title {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: var(--light-gray-color);
}
