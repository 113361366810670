.container {
  margin-bottom: 100px;
  /* width: 150%; */
}

.title {
  color: var(--light-gray-color);
  font-size: 15px;
}

.marqueeContainer {
  margin-top: 100px;
}
.brandImage {
  height: 120px;
  object-fit: contain;
  margin: 0 20px;
}
