.container {
  margin-top: 170px;
}

.badge {
  margin-bottom: 57px;
}

.title,
.title * {
  font-size: 50px;
  font-weight: 300;
}
.title {
  margin-bottom: 30px;
}
