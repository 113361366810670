.container {
  width: 200px;
  height: 250px;
  border: 2px rgba(73, 141, 76, 0.753) solid;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(73, 141, 76, 0.353);
  transition: all 0.3s ease;
}

.img {
  height: 210px;
  width: 100%;
  object-fit: cover;
}

.name {
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  font-weight: 400;
  transition: color 0.3s ease;
}

.container:hover {
  border-color: var(--primary-color);
}
.container:hover .name {
  color: var(--primary-color);
}
