.container {
  margin-top: 180px;
  margin-bottom: 100px;
  /* width: 150%; */
}

.title {
  color: var(--light-gray-color);
  font-size: 15px;
  margin-bottom: 100px;
}

.imagesContainer {
  display: flex !important;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;
  overflow: visible;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 470px) {
  .imagesContainer {
    gap: 15px;
  }
  .imagesContainer > * {
    flex-basis: calc(50% - 15px);
  }
  .imagesContainer * {
    font-size: 15px !important;
    text-align: center;
  }
}
