.button {
  border: 1px var(--gray-color) solid;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.button:hover {
  cursor: pointer;
}
.button img {
  /* width: 150px; */
  /* height: 150px; */
  animation: rotate linear infinite 5s;
}

.arrow {
  position: absolute;
  margin: auto;
  width: 35px;
  height: 35px;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
