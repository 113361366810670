.container {
  display: block;
  width: 300px;
  cursor: pointer;
  transition: all 0.4s ease-in;
  position: static;
  box-shadow: none;
}
.card {
  position: relative;
  display: flex;
  width: 100%;
  aspect-ratio: 1/1;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.4s ease;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
}
.illustration {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 0;
  transition: all 1s ease;
  object-fit: cover;
}
.name {
  transition: all 1s ease;
  transition: color 0.4s ease;
  font-size: 30px;
  font-weight: 500;
}
.cardTitle {
  /* position: inherit; */
  background-color: black;
  border-radius: 20px;
  white-space: nowrap;
  opacity: 0.8;
  padding: 5px 10px;
  height: fit-content;
  width: fit-content;
  /* margin: auto auto 10px 10px; */
  transition: all 1s ease;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

/* Hover animations */
.container:hover .name {
  color: var(--primary-color);
  text-decoration: underline;
}
.container:hover .illustration {
  transform: scale(1.2);
}
.container:hover .cardTitle {
  left: 50%;
  transform: translateX(-50%) scale(1.2);
  bottom: 25px;
  background-color: rgb(33, 44, 39);
}

/* Modal states */
.containerOpen {
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000000;
}
.containerOpen .name {
  position: absolute;
  opacity: 0;
}
.containerOpen .cardTitle {
  opacity: 0;
}
.containerOpen .card {
  /* width: fit-content; */
  width: 66vw;
  height: 90%;
  background-color: black;
  box-shadow: 0px 0px 1000px 1000px rgba(0, 0, 0, 0.7);
  border: 1.75px var(--gray-color) solid;
}
.containerOpen .illustration {
  opacity: 0.2;
}

/* ----------  CARD OPEN ---------- */
.openContent {
  padding: 30px;
  width: 100%;
}
.title {
  font-weight: 400;
  font-size: 30px;
}
.locationContainer,
.periodContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-left: 10px;
  margin-top: 10px;
}
.locationContainer span,
.periodContainer span {
  color: var(--light-gray-color);
  font-size: 18px;
}
.locationContainer *,
.periodContainer * {
  color: var(--primary-color);
}
.summary {
  color: white;
  font-style: italic;
  margin: 20px 10px;
}

.contentContainer {
  margin: 25px 0;
}
.description > * {
  font-size: 17px !important;
}

.sliderContainer {
}
.youtubeVideoContainer {
  width: 100% !important;
  margin: 0 auto;
  border: 1px var(--gray-color) solid;
  border-radius: 10px;
  overflow: hidden;
}
