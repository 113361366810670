.card {
  padding: 30px;
  border-radius: 30px;
  border: 1px var(--gray-color) solid;
  width: 350px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.name {
  font-size: 35px;
}
.occupation {
  font-size: 14px;
  text-align: right;
}

.photoContainer {
  width: 100%;
  border-radius: 30px;
  object-fit: cover;
  margin: 20px auto;
}
.photoContainer img {
  width: 100%;
  border-radius: 30px;
}

.infoContainer * {
  text-align: center;
  font-size: 22px;
}

.rights {
  color: var(--gray-color);
  font-size: 13.5px;
  text-align: center;
  margin: 20px 0;
}

.socialLinksContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin: 20px auto;
}
