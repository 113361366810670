.button {
  padding: 14px 40px;
  border-radius: 35px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 0.4s ease;
  transition: transform 0.2s ease-out;
  border: 2px var(--primary-color) solid;
}
.button:hover,
.button:hover * {
  cursor: pointer;
  background: transparent;
  color: var(--primary-color) !important;
}
.button:not(:hover) {
  transition: all 0.4s ease;
}
.button:active {
  transform: scale(0.95);
}

.iconButton {
  padding: 14px;
}

.label {
  color: black;
}

.icon * {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black !important;
}

.linkButton {
  background: none;
  padding: 0;
  border: none;
}
.linkButton .label {
  color: var(--primary-color);
}
.linkButton:hover .label {
  text-decoration: underline;
}
.linkButton .icon * {
  color: var(--primary-color) !important;
}

.inverted {
  background-color: transparent;
}
.inverted .label {
  color: var(--primary-color);
}
.inverted:hover .label {
  text-decoration: underline;
}
.inverted .icon * {
  color: var(--primary-color) !important;
}
