::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: var(--app-background-color);
}
