.badge {
  padding: 9px 22px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px var(--gray-color) solid;
  width: fit-content;
  font-size: 12px;
}

.iconBadge {
  padding: 14px;
}

.icon {
  transform: scale(0.8);
}

.icon * {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
}
