.container {
  border: 1px var(--gray-color) solid;
  border-radius: 30px;
  padding: 26px 0px;
  width: 60px !important;
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 12px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonContainer:hover {
  transition: all 0.3s ease;
}

.iconButton {
  cursor: pointer;
  width: 100%;
  z-index: 100;
}
.iconButton * {
  color: var(--light-gray-color);
  transition: color 0.35s ease;
}
.iconButton:hover * {
  color: var(--primary-color);
}

.label {
  position: absolute;
  right: 20px;
  text-align: center;
  font-size: 12px;
  color: var(--text-color) !important;
  background-color: #50505092;
  border-radius: 5px;
  padding: 3px 5.5px;
  opacity: 0;
  width: 0;
  overflow: hidden;
  transition: all 0.18s ease;
  z-index: -100;
}

.iconButton:hover + .label {
  right: 50px;
  overflow: visible;
  opacity: 1;
  width: 80px;
}
