/* FONTS */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
  font-size: 17px;
  color: var(--text-color);
}
p {
  font-weight: 300;
  line-height: 34px;
}
button {
  background: none;
  border: none;
}
