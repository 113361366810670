.mainContainer {
  display: flex;
  /* background-color: var(--app-background-color); */
  padding: 50px 50px 0 50px;
}
.leftContainer {
  /* border: 2px red solid; */
  position: relative;
  width: 500px;
}
.rightContainer {
  /* border: 2px green solid; */
  width: 100%;
  max-width: 800px;
  margin-right: auto;
  padding-right: 8vw;
}
.userCard {
  position: fixed;
}
.sideNavigation {
  position: fixed;
  right: 65px;
  top: 26%;
}

.userCardMobile {
  display: none;
}

@media screen and (max-width: 980px) {
  .sideNavigation {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  .mainContainer {
    padding: 20px 20px 0 20px;
  }
  .leftContainer {
    display: none;
  }
  .rightContainer {
    padding-right: 0;
  }
  .userCardMobile {
    display: block;
    margin-bottom: 70px;
    width: 100%;
  }
  .userCardMobile > div:nth-child(2) {
    max-width: 350px;
  }
  .userCardMobile > *:last-child {
    width: fit-content !important;
    margin: 0 auto;
  }
  .userCardMobile img {
  }
}
