.card {
  padding: 40px;
  border-radius: 20px;
  border: 1.25px var(--gray-color) solid;
  margin: 12px 0;
  transition: border-color 0.3s ease;
}
.card:hover {
  border-color: var(--primary-color);
}
.card:hover .projectsSpan {
  text-decoration: underline;
}

.iconContainer * {
  color: var(--primary-color);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
.header h3 {
  font-weight: 300;
  font-size: 30px;
}
.header p {
  color: var(--light-gray-color);
  line-height: 24px;
}

.projectsSpan {
  font-size: 12px;
}
