.container {
  position: relative;
  padding-left: 65px;
}

.lineContainer {
}
.lineDot {
  background-color: var(--gray-color);
  height: 13.5px;
  width: 13.5px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transition: background-color 0.23s ease;
}
.line {
  background-color: var(--gray-color);
  width: 1px;
  height: 100%;
  position: absolute;
  left: 6.5px;
  top: 6.5px;
}

.period {
  color: var(--light-gray-color);
  transition: color 0.23s ease;
  transform: translateY(-4px);
  font-size: 14.5px;
  font-weight: 300;
}

.positionContainer {
  padding: 15px 0;
}
.positionContainer h3 {
  font-weight: 400;
  font-size: 23px;
}
.positionContainer span {
  color: var(--light-gray-color);
  font-size: 15px;
  font-weight: 300;
}

.container .positionContainer:last-child {
  padding-bottom: 70px;
}
.container:last-child .positionContainer:last-child {
  padding-bottom: unset;
}

/* HOVER TRANSITIONS */
.container:hover .lineDot {
  background-color: var(--primary-color);
}

.container:hover .period {
  color: var(--primary-color);
}
