.paragraph {
  color: var(--light-gray-color);
}
.youtubeVideoContainer {
  width: 100% !important;
  margin: 0 auto;
  border: 1px var(--gray-color) solid;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 100px;
}
