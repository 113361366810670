.container {
  border: 1.25px var(--gray-color) solid;
  width: 550px;
  margin: auto;
  padding: 20px;
  border-radius: 20px;
}
.title {
  font-weight: 400;
  font-size: 30px;
}
.from {
  /* font-size: 30px !important; */
  font-style: italic;
  margin-left: 30px;
}
.img {
  object-fit: contain;
  /* width: 100%; */
  height: 100px;
  margin-top: 30px;
  margin: 30px auto;
}

@media screen and (max-width: 655px) {
  .container {
    width: 90%;
  }
  .container > *:last-child {
    height: 20px !important;
  }
  .container img {
    max-width: 100%;
    object-fit: contain;
  }
}
