.container {
  border: 1.25px var(--gray-color) solid;
  border-radius: 40px;
  padding: 40px;
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;
}
.header img {
  object-fit: cover;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.testimonialPersonInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.testimonialPersonInfo > span:first-child {
  font-size: 18px;
}
.testimonialPersonInfo > span:last-child {
  color: var(--light-gray-color);
  font-weight: 200;
  font-size: 15px;
}
.testimonialPersonInfo > span:last-child * {
  font-size: 15px;
}

.text {
  font-size: 16px;
  line-height: 21px;
  margin-top: 40px;
  font-weight: 200;
}
